import { Injectable, signal } from '@angular/core';
import { ViewTransitionInfo } from '@angular/router';

// interface ViewTransitionInfoExtended extends ViewTransitionInfo {
//   toSameRoute: boolean;
// }

@Injectable({
  providedIn: 'root'
})
export class CurrentTransitionService {
  readonly currentTransition = signal<ViewTransitionInfo | null>(null);
}
