import { isPlatformBrowser } from '@angular/common';
import { inject, Injectable, PLATFORM_ID, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { SocialNetworkLink } from '@usheru-hff/shared/domain';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FooterService {
  private readonly platform = inject(PLATFORM_ID);

  private customStyle = signal<string | undefined | null>(undefined);
  customStyle$ = toObservable(this.customStyle);

  customSocialLinkOutuput = new Subject<SocialNetworkLink[] | null>();
  defaultSocialLinkOutput = new Subject<boolean>();

  setCustomStyle(style: string) {
    const styleElem = `<style type="text/css">${style.replace(/\r?\n|\r|(\s\s\s*)/g, '')}</style>`;
    this.customStyle.set(styleElem);
  }

  removeCustomStyle() {
    this.customStyle.set(null);
  }

  setCustomSocialLinks(links: SocialNetworkLink[]) {
    this.customSocialLinkOutuput.next(links);
  }

  restoSocialLinks() {
    this.defaultSocialLinkOutput.next(true);
  }

  get isBrowser() {
    return isPlatformBrowser(this.platform);
  }
}
